import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Button,Modal } from '@material-ui/core';
import moment from 'moment';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import UpdateForm from './newsUpdateItem/UpdateForm'

const DELETEBLOG = gql`
mutation MyMutation($id: Int!) {
  delete_blog_by_pk(id: $id) {
    id
    title
    filename
  }
}
`

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700,
    textTransform:'uppercase',
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainContainer: {
    height:250,
    with:300,
    backgroundSize: 'cover'
  }
}));

const NesletterItem = props => {
  const { className, title, description, url, created,id,userType, ...rest } = props;
  const [deleteblog,{loading:loadingData,data,error:deleteError}] = useMutation(DELETEBLOG);
  const [openModal,setOpenModal] = useState(false);

  const classes = useStyles();

  const handleDelete = event  => {
     let answer = window.confirm(`are you sure to delete "${title}"?`);
     if(answer === true)
     deleteblog({variables:{id}})
    // event.preventDefault()
  }

  const handdleModal = ()=>{
    setOpenModal(!openModal);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
          <Grid
            container
            direction="column"
          >
            <Grid item>
              <Typography
                className={classes.title}
                color="primary"
                gutterBottom
                variant="title"
              >
                {title}
              </Typography>
              <Typography
              variant="subtitle2"
              >
                {/* {moment(moment()).fromNow()} */}
                {`${moment(created).format('YYYY-MM-DD')}`}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                {description}
              </Typography>
            </Grid>
            <Grid item>
              <Button
              variant="contained" 
              color="primary"
              onClick={()=>{
                window.open("https://ownersapp.costabonita.mx/assets/"+url)
                console.log("el url es " + url)
              }}
              >
              Open              
              </Button>
              &nbsp;
              &nbsp;
              {(userType === "admin" ? 
               <Button
              variant="contained" 
              color="primary"
              data-id={id}
              onClick={() => handleDelete(id,title)}>
              Erase
              </Button>
               :  <div></div> ) } 
              
              &nbsp;
              &nbsp;
              {(userType === "admin" ? 
               <Button
              variant="contained" 
              color="primary"
              data-id={id}
              onClick={() => {
                setOpenModal(!openModal);
              }}>
              update
              </Button>

               :  <div></div> ) }

          <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={openModal}
          className={classes.modal}
          >
            <UpdateForm handdleModal={handdleModal.bind(this)} id={id} title={title} description={description} url={url} />
          </Modal>
            </Grid>
          </Grid>
      </CardContent>
    </Card>
  );
};

NesletterItem.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  description: PropTypes.string,
  created: PropTypes.string
};

export default NesletterItem;
