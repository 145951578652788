
import React, { useState,Fragment } from 'react';
import clsx from 'clsx';
import PropTypes, { element, number } from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Button, Form, Input } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import PersonIcon from '@material-ui/icons/Person';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useQuery,useMutation,useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { useLocation,useParams } from 'react-router';
import firebase, { auth } from "firebase/app";
import "firebase/auth";
import "firebase/database";
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';

const   CREATERATING = gql`
mutation MyMutation($object: providers_ratings_insert_input!) {
  insert_providers_ratings_one(object: $object) {
    id
    comment
    id_provider
    id_user
    rating
  }
}
`;
const GETDATAPROVIDER = gql`
query MyQuery($id_provider: Int ! ) {
  providers_ratings(where: {id_provider:{_eq: $id_provider }}) {
    id
    id_provider
    comment
    id_user
    rating
  }
}
`;
const GETUSERROLEBYEMAIL = gql `
subscription getRole($email: String!) {
  users:users(where: {email: {_eq: $email}}) {
    first_name
    last_name
    email
    roles
    id
  }
}`;
const UPDATEPROVIDERRATE = gql`
  mutation MyMutation($id: Int !, $rate: numeric !) {
    update_providers_by_pk(pk_columns: {id: $id}, _set: {rate: $rate}) {
      rate
    }
}`;
const GETIFDATAEXIST = gql`
subscription MySubscription($id_user: Int !) {
  providers_ratings(where: {id_user:{_eq: $id_user}}) {
    id
    id_provider
    id_user
    rating
    comment
  }
}
`;
const INFOPROVIDER = gql `
subscription MySubscription($id: Int !) {
  providers_by_pk(id: $id) {
    id
    name
    phone
    email
    rate
  }
}
`;


const useStyles = makeStyles(theme => ({
    root: {
      height: '100%',
      width:'300px',
      height:'300px'
    },
    content: {
      alignItems: 'center',
      display: 'flex'
    },
    title: {
      fontWeight: 700,
      textTransform:'uppercase',
    },
    avatar: {
      backgroundColor: theme.palette.error.main,
      height: 56,
      width: 56
    },
    tableDimensions: {
      height: 400,
      width: 300,
    },
    table: {
      minWidth: 650,
    },
    icon: {
      height: 32,
      width: 32
    },
    difference: {
      marginTop: theme.spacing(2),
      display: 'flex',
      alignItems: 'center'
    },
    differenceIcon: {
      color: theme.palette.error.dark
    },
    differenceValue: {
      color: theme.palette.error.dark,
      marginRight: theme.spacing(1)
    },
    starColored: {
    color: 'yellow'
    }, 
    table: {
        minWidth: 650,
      },
    count:{
        backgroundColor: 'white',
       
      },
    mainContainer: {
      // height:250,
      // with:300,
      backgroundSize: 'cover'
    }
  }));

  const labels = {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
  };
  
  const RateItem = ( props ) => {
    const { id,userId, userRating, userComent,providerName } = props;
    const [ createRate,{loading, error, data} ]  = useMutation(CREATERATING);
    const emailCustom =  firebase.auth().currentUser && firebase.auth().currentUser.email;
    
    const {data:infoData,loading:infoloading,error:infoerror} = useSubscription(INFOPROVIDER,{variables:{id}});
    const [updateRate,{loading:updateLoading,data:updateData,error:updateError}] = useMutation(UPDATEPROVIDERRATE);
    const {data:dataRatings,loading:loadingRatings,error:errorRatings} = useQuery(GETDATAPROVIDER,{variables:{id_provider:id}});
    const {data:userData,loading:userLoadingError,error:userDataError} = useSubscription(GETUSERROLEBYEMAIL,{variables:{email:emailCustom}});
    const {data:existingData,loading:exixtingLoading,existingError} = useSubscription(GETIFDATAEXIST,{variables:{id_user:userId}});
    const [value, setValue] = React.useState(1);
    const [hover, setHover] = React.useState(-1);
    const classes = useStyles();

      const [values, setValues] = useState(exixtingLoading ? 
      {
        name: "",
        id_provider: id,
        rating: value,
        comment: "",
        id_user: 1 
      }
      :
      {
        name: "",
        id_provider: id,
        rating: existingData.providers_ratings[0].rating,
        comment: existingData.providers_ratings[0].comment,
        id_user: existingData.providers_ratings[0].id_user 
      }
      );
     
      
      //  const [values, setValues] = useState( 
      //    {
      //      name: "",
      //      id_provider: id,
      //      rating: value,
      //      comment: "",
      //      id_user: 1 
      //    });

      const saveRating = (idUser) => {
        if (values.comment === "") {
           alert("Please leave a comment");
        } else {
          createRate({
            variables:{
              object:{
               rating: value,
               id_user: idUser,
               id_provider: id,
               comment: values.comment
              }}
          })
        }
      };

      const sumScore = (data_Array) => {
        console.log(id+userId+ userRating+ userComent+providerName);
        saveRating(userId)  
         const average =  Object.values(data_Array).length;
         let suma = 0;
         data_Array.forEach(element => {
            suma = suma + element.rating
              });
          let total= `${suma/average}`;
          console.log("suma: "+ suma + " averge: "+ Object.values(data_Array).length)
          console.log("promedio es: " + total);
          console.log(parseFloat(total).toFixed(2) );

          updateRating(parseFloat(total).toFixed(2) );
      };
        const updateRating = (total) => {
          updateRate({variables:{
            id:id,
            rate:total,
          }})
        }

      const handleChange = event => {
        setValues({
          ...values,
          [event.target.name]: event.target.value
        });
      };


    if (loading || loadingRatings || userLoadingError || updateLoading || exixtingLoading || infoloading )
    return <p>loading</p>


    return(
   
<Grid
  container
  direction="row"
  justify="center"
  alignItems="baseline"
  className={classes.count}>
<Card>
  <Grid></Grid>
 <form className = {classes.mainContainer}>

 <div className={classes.root}>
   {console.log(existingData.providers_ratings[0])}
   <h3>{infoData.providers_by_pk.name}</h3>
   <Grid item>
      {/* <Grid container><PersonIcon/><Typography style={{marginLeft:"8px"}}>{infoData.providers_by_pk.name}</Typography></Grid> */}
      <Grid container><MailIcon/><a href={`mailto:${infoData.providers_by_pk.email}`}><Typography style={{marginLeft:"8px"}}>{infoData.providers_by_pk.email}</Typography></a></Grid>
      <Grid container><PhoneIcon/><a href={`tel:${infoData.providers_by_pk.phone}`}><Typography style={{marginLeft:"8px"}}>{infoData.providers_by_pk.phone}</Typography></a></Grid>
      <Grid><Rating name="half-rating-read" value={infoData.providers_by_pk.rate} precision={0.5} readOnly/>{infoData.providers_by_pk.rate}</Grid>
  </Grid>
 
  <br></br>
  <br></br>

   
    </div>
    <h5>Your Rate</h5>
   <Grid
  container
  direction="row"
  justify="flex-start"
  alignItems="center">
    {/* {(existingData.providers_ratings[0].comment == null ?  */}
    <Rating
        name="hover-feedback"
        value={value || existingData.providers_ratings[0].rating}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
      /> 
      {/* : 

      <Rating
        name="hover-feedback"
        value={existingData.providers_ratings[0].rating}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
      />

      )} */}
{/* {(existingData.providers_ratings[0].comment == null ?  */}
{value !== null && <Box ml={2}>{labels[hover !== -1 ? hover : value]}</Box>}
{/* :
<Box> {existingData.providers_ratings[0].rating} </Box>
)} */}

</Grid>

        <p>
        <TextField
          id="outlined-multiline-static"
          label="Leave a Comment"
          multiline
          rows={4}
          value={values.comment}
          onChange={handleChange}
          name="comment"
          //defaultValue=""
          variant="outlined"/>
        </p>
       
        <Button variant="contained"
         color="primary"
         onClick= {() => sumScore(dataRatings.providers_ratings)}>
        Save
      </Button>
      </form>
      </Card>   
  </Grid>
    );
    };

export default RateItem;