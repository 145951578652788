import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from "@material-ui/icons/Edit"
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Collapse,
  Typography,
  Box,
  IconButton
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useQuery,useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase, { auth } from "firebase/app";
import "firebase/auth";
import "firebase/database";


const deleteArrivalGql = gql`
mutation MyMutation($id: Int!) {
  delete_arrivals_by_pk(id: $id) {
    id
    name
    model_car
  }
}
`;





const moment = require('moment');

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const UsersTable = props => {
  const { className, arrivals, search,dataLogedUser, ...rest } = props;

  const classes = useStyles();
  let history = useHistory();
  const [deletearrival,{loading:loadingData,data,error:deleteError}] = useMutation(deleteArrivalGql);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(true);
 

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const handleEdit = event => {
    let id = event.currentTarget.getAttribute('data-id');
    history.push(`/arrivals/edit/${id}`)
  }

  const filter = (current)=>{
    return (search === '' || current.name.toLowerCase().search(search.toLowerCase()) >= 0);
  };

  const deleteArrival= (id) =>{
    let answer = window.confirm(`are you sure to delete this arrival ?`);
    if(answer === true)
    deletearrival({variables:{id}})
  };

  return (
     <Card
       {...rest}
       className={clsx(classes.root, className)}
     >
       <CardContent className={classes.content}>
         <PerfectScrollbar>
           <div className={classes.inner}>
             <Table>
               <TableHead>
                 <TableRow>
                   <TableCell>Name</TableCell>
                   <TableCell>Condominium</TableCell>
                   <TableCell>Status</TableCell>
                   <TableCell>Arrival Date</TableCell>
                   {/* <TableCell>Entry Date</TableCell> */}
                   <TableCell>Comes By</TableCell>
                   <TableCell>Actions</TableCell>
                   
                 </TableRow>
               </TableHead>
               <TableBody>
                 {arrivals
                 .filter(filter)
                 .slice(0, rowsPerPage).map(arrival => (
                   <TableRow
                     className={classes.tableRow}
                    hover
                     key={arrival.id}
                     selected={selectedUsers.indexOf(arrival.id) !== -1}
                   >
                     <TableCell>
                       {arrival.name}
                     </TableCell>
                    <TableCell>
                      {arrival.apartment_name}
                      {/* {arrival.apartment
                          ? `${arrival.apartment.number}-${
                                arrival.apartment.building_id === 1
                                    ? "A"
                                    : arrival.apartment.building_id === 2
                                    ? "B"
                                    : arrival.apartment.building_id === 3
                                    ? "C"
                                    : "D"
                            }`
                          : ""} */}
                     </TableCell>
                     <TableCell>{'Scheduled'}</TableCell>
                     <TableCell>
                       {moment(arrival.arrival_date).format('LL')}
                     </TableCell>
                     {/* <TableCell>{(arrival.checking_in == null)?'-':moment(arrival.checking_in)}</TableCell> */}
                     <TableCell>{(arrival.arrived_in)}</TableCell>
                     <TableCell>
                       <IconButton 
                       data-id={arrival.id}
                       onClick={handleEdit}
                       aria-label="edit">
                         <EditIcon />
                       </IconButton>
                       <IconButton aria-label="delete" >
                         <DeleteIcon 
                         onClick={()=> deleteArrival(arrival.id)}/>
                       </IconButton>
                     </TableCell>

                    <TableCell>
                     <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                   {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                   </IconButton>
                    </TableCell>
                     
                   </TableRow>
                 ))}
               </TableBody>
             </Table>
           </div>
         </PerfectScrollbar>
       </CardContent>
       <CardActions className={classes.actions}>
         <TablePagination
           component="div"
           count={arrivals.filter(filter).slice(0, rowsPerPage).length}
           onChangePage={handlePageChange}
           onChangeRowsPerPage={handleRowsPerPageChange}
           page={page}
           rowsPerPage={rowsPerPage}
           rowsPerPageOptions={[5, 10, 25]}
         />
       </CardActions>
     </Card>



  );
};


UsersTable.propTypes = {
  className: PropTypes.string,
  arrivals: PropTypes.array.isRequired,
  search: PropTypes.string
};

export default UsersTable;
