import React, { useState, useEffect, useContext } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import theme from '../../theme';
import { Minimal } from '../../layouts';
import firebase from 'firebase/app';
import Callback from './Callback';
import SignIn from '../SignIn';
import App from '../../App';
import Login from './Login';
import userDataStore from '../../UserDataStore';
export const AuthFirebaseContext = React.createContext();
export const useAuthFirebase = () => useContext(AuthFirebaseContext);
firebase.initializeApp({
  apiKey: 'AIzaSyDeaK_6gkxJVbVoaUz2RUeTftVFPZ_8cck',
  authDomain: 'owners-272001.firebaseapp.com',
  databaseURL: 'https://owners-272001.firebaseio.com',
  projectId: 'owners-272001',
  storageBucket: 'owners-272001.appspot.com',
  messagingSenderId: '834650896343',
  appId: '1:834650896343:web:098ad040233b330c79bd92',
  measurementId: 'G-DDG0J70XQK'
});
export const AuthFirebaseProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idToken, setIdToken] = useState('');

  useEffect(() => {
    // const initFirebase = async () => {

    //   //const isAuthenticated = (userDataStore.userData.jwt !== '')?true:false;
    //   console.log(firebase.auth().currentUser);
    //   const isAuthenticated = (firebase.auth().currentUser)?true:false
    //   setIsAuthenticated(isAuthenticated);

    //   if (isAuthenticated) {
    //     const token = userDataStore.userData.jwt;
    //     setIdToken(token);
    //   }

    //   setLoading(false);
    // };
    // initFirebase();
    // eslint-disable-next-line
    return firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const token = await user.getIdToken();
        const idTokenResult = await user.getIdTokenResult();
        const hasuraClaim =
          idTokenResult.claims['https://hasura.io/jwt/claims'];
        if (hasuraClaim) {
          let userData = {
            uid: user.uid,
            fullName: '',
            email: user.email,
            jwt: idTokenResult.token
          };
          userDataStore.updateUser(userData);
          const isAuthenticated = user ? true : false;
          setIsAuthenticated(true);

          if (isAuthenticated) {
            const token = idTokenResult.token;
            setIdToken(token);
          }

          setLoading(false);
        }
      } else {
      }
    });
  }, []);

  const handdleSignIn = async values => {
    setLoading(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(values.email, values.password)
      .then(async ({ user }) => {
        const token = await user.getIdToken();
        let userData = {
          uid: user.uid,
          fullName: '',
          email: user.email,
          jwt: token
        };
        setIdToken(token);
        userDataStore.updateUser(userData);
        setIsAuthenticated(true);
        localStorage.setItem('uuid', user.uid);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handdleSignOut = async () => {
    firebase.auth().signOut();
    setIdToken('');
    userDataStore.clearUser();
    setIsAuthenticated(false);
  };

  if (loading) {
    return <Callback />;
  }
  if (!isAuthenticated) {
    return (
      <AuthFirebaseContext.Provider
        value={{
          isAuthenticated,
          //user,
          loading,
          handdleSignIn,
          handdleSignOut
        }}>
        <ThemeProvider theme={theme}>
          <Minimal>
            <SignIn />
          </Minimal>
        </ThemeProvider>
      </AuthFirebaseContext.Provider>
    );
  }

  return (
    <AuthFirebaseContext.Provider
      value={{
        isAuthenticated,
        //user,
        loading,
        handdleSignIn,
        handdleSignOut
      }}>
      {children}
      <App idToken={idToken} />
    </AuthFirebaseContext.Provider>
  );
};
