import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import { useMutation, useSubscription, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Pool } from '@material-ui/icons';
import firebase, { auth } from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const UPDATEBLOG = gql`
  mutation MyMutation(
    $id: Int!
    $title: String!
    $description: String!
    $filename: String!
  ) {
    update_rules_and_regulations_by_pk(
      pk_columns: { id: $id }
      _set: { title: $title, description: $description, filename: $filename }
    ) {
      filename
      description
      title
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    flexGrow: 1
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',

    height: 'adjust',
    with: 'adjust'
  },
  alignTop: {
    alignItems: 'top'
  },
  backColor: {
    backgroundColor: 'white'
  },
  mainContainer: {
    height: 250,
    with: 300,
    backgroundSize: 'cover'
  }
}));

const AccountDetails = props => {
  const {
    className,
    handdleModal,
    validateArea,
    datetime,
    appointmente_data,
    indexTab,
    addItem,
    id,
    title,
    description,
    url,
    ...rest
  } = props;
  const classes = useStyles();
  const [updateBlog, { loading, data, error }] = useMutation(UPDATEBLOG);
  const [selectedFile, setSelectedFile] = useState(null);

  const [values, setValues] = useState({
    title: title,
    description: description,
    filename: url
  });

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  const handleOnSubmit = event => {
    // onFileUpload()

    event.preventDefault();
  };

  const handleChange2 = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const onFileChange = event => {
    // Update the state
    setSelectedFile(event.target.files[0]);
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;
  //END FORMS

  const onFileUpload = () => {
    // Create an object of formData
    const formData = new FormData();

    if (selectedFile === null) {
      updateBlog({
        variables: {
          id: id,
          title: values.title,
          description: values.description,
          filename: values.filename
        }
      });
      handdleModal();
      console.log('no hay fila seleccionada ' + selectedFile);
    } else {
      console.log('si hay file: ' + selectedFile + '' + selectedFile.name);

      // Update the formData object
      formData.append('file', selectedFile, selectedFile.name);
      return fetch('https://services.costabonita.mx/upload', {
        method: 'POST',
        body: formData
      })
        .then(function(response) {
          return response.json();
        })
        .then(function(json) {
          updateBlog({
            variables: {
              id: id,
              title: values.title,
              description: values.description,
              filename: `${json.data.folder}/${json.data.name}`
            }
          });
          handdleModal();
        });
    }
  };

  return (
    <form onSubmit={handleOnSubmit} className={classes.form}>
      <Card>
        <CardHeader
          subheader="Add new info"
          title="Update"
          className={classes.alignTop}
        />
        <Divider />
        <CardContent>
          <TextField
            className={classes.separation}
            error={hasError('title')}
            fullWidth
            helperText={hasError('title') ? formState.errors.title[0] : null}
            label="Title"
            name="title"
            // className={classes.backwhite}
            onChange={handleChange2}
            value={values.title}
            // value={title}
            variant="outlined"
          />

          <TextField
            className={(classes.separation, classes.backwhite)}
            error={hasError('description')}
            fullWidth
            helperText={
              hasError('description') ? formState.errors.description[0] : null
            }
            multiline
            label="Description"
            name="description"
            onChange={handleChange2}
            value={values.description}
            // value={description}
            variant="outlined"
            rows={4}
          />

          <TextField
            className={classes.separation}
            label="File"
            onChange={onFileChange}
            type="file"
          />

          {/* <TextField
                type="hidden"
                name="filename"
                onChange={handleChange}
                value={formState.values.filename || 'No file Uploaded'}
                /> */}

          <CardActions>
            <Button
              className={classes.separation}
              class="btn btn-primary"
              color="primary"
              //disabled={((!formState.isValid) || loading)}
              // fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={() => onFileUpload()}>
              Save
            </Button>

            <Button
              variant="contained"
              class="btn btn-primary"
              onClick={handdleModal}>
              Close
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    </form>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string,
  handdleModal: PropTypes.func,
  datetime: PropTypes.string,
  appointmente_data: PropTypes.any
};

export default AccountDetails;
