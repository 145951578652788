import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { createBrowserHistory } from "history";

const RouteWithLayout = props => {
  const { layout: Layout, component: Component, authState, logoutCallback, ...rest } = props;
  const history = createBrowserHistory();
  return (
    <Route
      {...rest}
      history={history}
      render={matchProps => {
          if(authState.status === 'out'){
            return (
              <Redirect to={"/sign-in"} />
            )
          } else {
            return (
              <Layout {...props}>
                <Component {...props} {...matchProps} />
              </Layout>
            )
          }
      } 
        }
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
  authState: PropTypes.object
};

export default RouteWithLayout;
