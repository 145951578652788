import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Modal } from '@material-ui/core';
import { ConciergeItem, PalapaPdf, AccountStatement } from './components';
import UserDataStore from '../../UserDataStore';
import { useQuery, useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase, { auth } from "firebase/app";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
// import DateFnsUtils from "@date-io/date-fns";



const PALAPA = gql`
  query getPalapa {
    palapa_items(order_by: { id: asc }) {
      file        
      id
      img
      name
    }
  }
`;
const GETUSERROLEBYEMAIL = gql `
  query getRole($email: String!) {
    users(where: {email: {_eq: $email}}) {
      first_name
      last_name
      email
      roles
    }
  }
`;

let arrayPhotos = new Array();

arrayPhotos[0] = new Array();
arrayPhotos[0].src = 'images/calendar.jpg';

arrayPhotos[1] = new Array();
arrayPhotos[1].src = 'images/cartelMenu.jpg';

arrayPhotos[2] = new Array();
arrayPhotos[2].src = 'images/especial.png';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const Concierge = () => {
  const { loading, data, error } = useQuery(PALAPA);
  const [openModal, setOpenModal] = useState(false);
  const [fileModal, setFileModal] = useState('');
  const handdleModal = file => {
    // setFileModal(file);
    // setOpenModal(!openModal);
    window.open(`${file}`, '_blank');
    // window.open(`https://drive.google.com/file/d/${file}/preview`,'_blank');
  };
  UserDataStore.setTitle('palapa');
  const classes = useStyles();

  const emailCustom =  firebase.auth().currentUser && firebase.auth().currentUser.email;
  const {data:userDataRol, loading: userLoading} = useQuery(GETUSERROLEBYEMAIL,{variables:{email:emailCustom}});

  if (loading || userLoading)
    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          loading.....
        </Grid>
      </div>
    );
    console.log(userDataRol?.users[0]?.roles     )
  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        {data?.palapa_items?.map(item => {
          return (
            <Grid item lg={4} md={6} xs={12}>
              <ConciergeItem
                file={item.file}
                img={item.img}
                label={item.name}
                onClick={handdleModal.bind(this)}
              />
            </Grid>
          );
        })}
      </Grid>
      {(userDataRol?.users[0]?.roles === 'user')
      ?
        <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
          <AccountStatement/>
        </MuiPickersUtilsProvider>
      :
        <>
        </>
      }
      
      <Modal
        className={classes.modal}
        disableAutoFocus
        disableEnforceFocus
        disablePortal
        open={openModal}>
        <PalapaPdf close={handdleModal.bind(this)} file={fileModal} />
      </Modal>
    </div>
  );
};

export default Concierge;
