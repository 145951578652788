import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Divider,
  FormControlLabel,
  Checkbox,
  Typography,
  Button,
} from '@material-ui/core';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import Alert from '@material-ui/lab/Alert';
import { gql } from 'apollo-boost';
import { useSubscription, useMutation } from '@apollo/react-hooks';
import firebase from 'firebase';

const MYSETTINGSQUERY = gql`
query MyQuery($uid:String!) {
  users(where: {uid: {_eq: $uid}}) {
    public_contact_info
  }
}
`;

const MYSETTINGSMUTATION = gql`
mutation MyMutation($uid: String!, $public_contact_info: Boolean!) {
  update_users(where: {uid: {_eq: $uid}}, _set: {public_contact_info: $public_contact_info}) {
    affected_rows
    returning {
      public_contact_info
    }
  }
}
`;
const useStyles = makeStyles(() => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const Notifications = props => {
  const { className, ...rest } = props;
  const [isTrue, setIsTrue] = React.useState(false);
  const contactInfo = false;
  let user = firebase.auth().currentUser;
  const {loading:loadingSettings,data:dataSettings,error:errorSettings} = useSubscription(MYSETTINGSQUERY,{variables:{uid:user.uid}});
  const [updateSettings,{ data:dataSettingsResponse }] = useMutation(MYSETTINGSMUTATION);
  const classes = useStyles();
  


  const handdleClick = (event) => {
    updateSettings({variables:{uid:user.uid,public_contact_info:isTrue}})
    // console.log("handel click "+ dataSettings.users[0].public_contact_info);
   if (isTrue === true ){
     alert("Your information is public everyone can see it");
   } else {
   alert("Your info is private, nobody can see it ");
  }};



 
   
  
  if(loadingSettings)
  return <p>..loading</p>
  if(errorSettings != undefined)
  return <p>Error: {errorSettings.message}</p>
  const isChequed = dataSettings.users[0].public_contact_info;
  const {public_contact_info} = dataSettings.users[0].public_contact_info;
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form>
        <CardHeader
          subheader="Manage your account"
          title="Account settings"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={6}
            wrap="wrap"
          >
            <Grid
              className={classes.item}
              item
              md={4}
              sm={6}
              xs={12}
            >
              <Typography
                gutterBottom
                variant="h6"
              >
                {/* Account */}
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    color="green"
                    defaultChecked={isChequed}
                    value="chekedA"
                    onChange={e=> {
                      // console.log("target checked? - ", e.target.checked);
                      setIsTrue(e.target.checked)
                    }}
                  />
                }
                label="If you want to share your info"
              />
             <span class="badge badge-pill badge-success">(click on the box)</span>
            </Grid> 
            <br></br>
            
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
        
 


          <Button
            onClick={() => handdleClick()}
            color="primary"
            variant="outlined"
          >
            Save
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

Notifications.propTypes = {
  className: PropTypes.string
};

export default Notifications;
