import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import { useMutation,useSubscription, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Pool } from '@material-ui/icons';
import firebase, { auth } from "firebase/app";
import "firebase/auth";
import "firebase/database";


const INSERTAPPOINTMENT = gql`
mutation insert_appointment($object: appointments_insert_input!) {
  insert_appointments_one(object: $object) {
    activity
    appointment_length
    common_area_id
    created_at
    created_by
    datetime_scheduled
    id
    title
    updated_at
  }
}
`;

const GETUSERS = gql`
query getUsers {
  users {
    last_name
    id
    first_name
  }
}
`;

const DELETEAPOINTMENT = gql`
mutation MyMutation($id: Int !) {
  delete_appointments_by_pk(id: $id) {
    id
    title
  }
}
`;

const GETUSERROLEBYEMAIL = gql `
subscription getRole($email: String!) {
  users:users(where: {email: {_eq: $email}}) {
    first_name
    last_name
    id
    email
    roles
  }
}`;



const useStyles = makeStyles(() => ({
  root: {}
}));

const AccountDetails = props => {
  const { className, handdleModal,validateArea, datetime,appointmente_data,createdby,areaName, ...rest } = props;
  const { data:dataUsers, loading:loadingUsers, error:errorUsers } = useQuery(GETUSERS);
  const emailCustom =  firebase.auth().currentUser && firebase.auth().currentUser.email;
  const classes = useStyles();
  const {data:userData,loading:userLoadingError,error:userDataError} = useSubscription(GETUSERROLEBYEMAIL,{variables:{email:emailCustom}});
  const [addAppointment, { data,loading }] = useMutation(INSERTAPPOINTMENT);
  const [deleteApointmen,{loading:loadingData,data:dataDelete,error:deleteError}] = useMutation(DELETEAPOINTMENT);
  

  const [values, setValues] = useState({
    activity:appointmente_data.activity,
    appointment_length:appointmente_data.appointment_length,
    common_area_id:appointmente_data.common_area_id,
    created_by:appointmente_data.created_by,
    datetime_scheduled:appointmente_data.datetime_scheduled,
    title:appointmente_data.title
  });
  

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleDelete = (id,title ) => {
    if (appointmente_data.id === null){
      return (<Alert severity="warning">This is a warning alert — check it out!</Alert>)
    } else {
    let answer = window.confirm(`are you sure to delete "${appointmente_data.title}"?`);
    if(answer === true)
     deleteApointmen({variables:{id}})
   // event.preventDefault()
   handdleModal();
 }}



  const minutes = [
    0,
    30,
    60,
    90,
    120,
    150,
    180
  ];
  const minutesForCourt = [
    0,
    30,
    60,
    90,
    120
  ];

  const saveRecord = (commonAreaId) => {
    addAppointment({variables: { 
      "object": {
       title: values.title,
       created_by: userData.users[0].id,
       activity: values.activity,
       appointment_length: values.appointment_length,
       datetime_scheduled: values.datetime_scheduled,
       activity: "pricketball",
       common_area_id: commonAreaId  } 
     }})

  }


  const saveInfo = () => {
    console.log("test " +  validateArea);
    if( validateArea === 6 || validateArea === 7) {
        console.log("positivo, cancha pricketball")
        addAppointment({variables: { 
         "object": {
          title: values.title,
          created_by: userData.users[0].id,
          activity: values.activity,
          appointment_length: values.appointment_length,
          datetime_scheduled: values.datetime_scheduled,
          activity: "pricketball",
          common_area_id: 5  } 
        }})
        addAppointment({variables: { 
          "object": {
           title: values.title,
           created_by: userData.users[0].id,
           activity: values.activity,
           appointment_length: values.appointment_length,
           datetime_scheduled: values.datetime_scheduled,
           activity: values.activity,
           common_area_id: values.common_area_id  } 
         }}).then(()=>{
          handdleModal()
        });
      } 
       if( validateArea === 5 ) {
         console.log("positivo 2, cancha de tenis 2 ")
         saveRecord(6)
         saveRecord(7)
        
         addAppointment({
           variables:{
             "object": values        
           }
         }).then(()=>{
           handdleModal()
         });
       }
      else {
        console.log("negativo " + validateArea)
        addAppointment({variables: { 
          "object": {
           title: values.title,
           created_by: userData.users[0].id,
           activity: values.activity,
           appointment_length: values.appointment_length,
           datetime_scheduled: values.datetime_scheduled,
           activity: values.activity,
           common_area_id: values.common_area_id  } 
         }}).then(()=>{
      handdleModal()
    });
  }
}
  
    

  // const saveCourt2 = (id) => {
  //   if(id == 9) {
  //     console.log(" pricketball area ");
  //   }
  //   else {
  //     console.log(" no es una area de pickball");
  //   }
  // };
  if (userLoadingError) 
  return <p>loading</p>

  if(loadingUsers)
  return <Card
  {...rest}
  className={clsx(classes.root, className)}
  ><h1>loading.....</h1></Card>
  return <Card
  {...rest}
  className={clsx(classes.root, className)}
>
  <form
    autoComplete="off"
    noValidate
  >
    <CardHeader
      subheader="Add new reservation"
      title={areaName}
    />
    <Divider />
    <CardContent>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          md={12}
          xs={12}
        >
          <TextField
            fullWidth
            helperText="Please specify the first name"
            label="Your Name"
            margin="dense"
            name="title"
            onChange={handleChange}
            required
            value={values.title}
            variant="outlined"
          />
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
        >
          <TextField
            fullWidth
            label="Scheduled date"
            margin="dense"
            name="datetime_scheduled"
            onChange={handleChange}
            InputProps={{
              readOnly: true,
            }}
            required
            value={moment(values.datetime_scheduled).format('YYYY-MM-DD')}
            variant="outlined"
          />
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
        >
          <TextField
            fullWidth
            label="Scheduled time"
            margin="dense"
            name="appointment_length"
            onChange={handleChange}
            InputProps={{
              readOnly: true,
            }}
            required
            value={moment(values.datetime_scheduled).format('hh:mm a')}
            variant="outlined"
          />
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
        >
          <TextField
            fullWidth
            label="Appointment length (minutes)"
            margin="dense"
            name="appointment_length"
            onChange={handleChange}
            required
            select
            // eslint-disable-next-line react/jsx-sort-props
            SelectProps={{ native: true }}
            value={ values.appointmentLength === 0 ? 30 : values.appointmentLength }
            variant="outlined"
          >
            
            {appointmente_data.common_area_id === 4 || appointmente_data.common_area_id === 5 || appointmente_data.common_area_id === 6 || appointmente_data.common_area_id === 7 ? 
            
            minutesForCourt.map(option => (
              <option
                key={option}
                value={option}
              >
                {option}
              </option>
            )) : 
            
            minutes.map(option => (
              <option
                key={option}
                value={option}
              >
                {option}
              </option>
            )) }
            {/* {minutes.map(option => (
              <option
                key={option}
                value={option}
              >
                {option}
              </option>
            ))} */}
          </TextField>
        </Grid>
      </Grid>
    </CardContent>
    <Divider />
    <CardActions>
      
      
      <Button
        color="primary"
        created_by 
        style={{display:(appointmente_data.id === undefined || appointmente_data.created_by === 1)?'initial':'none'}}
        onClick={saveInfo} 
      >
        Save reservation
      </Button>


      {/* {(userData.users[0].id == appointmente_data.created_by ?
      <Button
        // color="primary"
        created_by 
        // style={{display:(appointmente_data.id === undefined || appointmente_data.created_by === 1)?'initial':'none'}}
        onClick={() =>  handleDelete(appointmente_data.id,appointmente_data.title)}>
        ERASE
      </Button>
       :
       <div></div>) } */}


      { (userData.users[0].roles === "admin" || userData.users[0].id === appointmente_data.created_by ?
      <Button
        // color="primary"
        created_by 
        // style={{display:(appointmente_data.id === undefined || appointmente_data.created_by === 1)?'initial':'none'}}
        onClick={() =>  handleDelete(appointmente_data.id,appointmente_data.title)}>
        
        ERASE
      </Button>
       :
       <div></div>) }
       
      <Button
        variant="contained"
        onClick={ handdleModal } 
      >
        Close
      </Button>
    </CardActions>
  </form>
</Card>

};

AccountDetails.propTypes = {
  className: PropTypes.string,
  handdleModal: PropTypes.func,
  datetime: PropTypes.string,
  appointmente_data: PropTypes.any
};

export default AccountDetails;
