import React, {useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid,AppBar,Tabs,Tab,Typography,Box,Button,Collapse,TextField } from '@material-ui/core';
import userDataStore from '../../UserDataStore';
import { useMutation, useSubscription,useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import AdminItem from './components/AdminItem';
import firebase, { auth } from "firebase/app";
import "firebase/auth";
import "firebase/database";
import emailjs from 'emailjs-com';

const CREATEADMITEM = gql `
mutation MyMutation($object: admin_items_insert_input !) {
  insert_admin_items_one(object: $object) {
    created_at
    description
    filename
    title
    type
    updated_at
  }
}

`

const NEWSQL = gql`
subscription MyQuery {
  admin_items(order_by: {id: desc}) {
    created_at
    description
    filename
    id
    title
    type
    updated_at
  }
}
`;

const GETUSERROLEBYEMAIL = gql `
subscription getRole($email: String!) {
  users:users(where: {email: {_eq: $email}}) {
    first_name
    last_name
    email
    roles
  }
}`;

const USERSDATA = gql`
query MyQuery {
  users(where: {send_notifications: {_eq: true}}) {
    first_name
    last_name
    email
    id
  }
}
`;



interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4),
      flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
    },
    backwhite: {
      background: "white"
    },
    fontcolor: {
      fontcolor: "white"
    }

}));

const News = ()=> {
    userDataStore.setTitle('');
    userDataStore.setTitle('Administration');
    const emailCustom =  firebase.auth().currentUser && firebase.auth().currentUser.email;
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const { loading, error, data } = useSubscription(NEWSQL);
    const {data:userData,loading:userLoadingError,error:userDataError} = useSubscription(GETUSERROLEBYEMAIL,{variables:{email:emailCustom}});
    const [admItem,{data:dataItem}] = useMutation(CREATEADMITEM);
    const {loading:loadingUsers, error:errorUsers, data:dataUsers } = useQuery(USERSDATA);

 //se configuran los forms
    const [showForm, setShowForm] = useState(false);
    const [selectedFile,setSelectedFile] = useState(null);
    const [formState, setFormState] = useState({
      isValid: false,
      values: {},
      touched: {},
      errors: {}
    });
    
    const handleChangeIndexTab = (event: React.ChangeEvent<{}>, newValue: number) => {
       setValue(newValue);
     };


    const handleChange = event => {
      event.persist();
      
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value
        },
        touched: {
          ...formState.touched,
          [event.target.name]: true
        }
      }));
    };
   

    const fileValidator = () =>{
      if (selectedFile === null){
        alert("No file selected");
        // saveTest()
        let values = formState.values;
        admItem({variables:{
          object: {
            title: values.title,
            description: values.description,
            filename: "no file",
            type: (value === 0  ? "maintenance_fee" : "minutes")
          }
        }})
        sendEmailNotification();
      }
      else{
        // Create an object of formData 
      const formData = new FormData(); 
      console.log("desde on onFileUpload");
       // Update the formData object 
       formData.append( 
         "file", 
         selectedFile, 
         selectedFile.name 
       ); 
       return fetch('https://services.costabonita.mx/upload',{
         method: 'POST',
         body: formData
       })
       .then(function(response){   
         return response.json();
       })
       .then(function(json){
        
         console.log("guardar objeto");
         let values = formState.values;
         values.filename = (`${json.data.folder}/${json.data.name}`) ;
         values.type =  (value === 0  ? "maintenance_fee" : "minutes")
         sendEmailNotification(`${json.data.folder}/${json.data.name}`);
         admItem({variables:{
           "object":values
         }})
       })
      }
    }

    const sendEmailNotification = (Link) => {
      console.log("sendEmailfunction");
      const usersEmail = dataUsers.users.map( usersEmailsList =>{
        return usersEmailsList.email } );
        console.log(usersEmail);
        let correos = ["polito.no@hotmail.com","polo.nu16@gmail.com" , "carloscostabonita@yahoo.com.mx","mireya_costabonita@yahoo.com"]
  const frmContact = { userEmail: usersEmail, notification_type:'Administration',file_link:'https://ownersapp.costabonita.mx/assets/'+ Link };
  // const [contact,setContact] = useState(frmContact);
        emailjs.send('CostaBonita','notNews', frmContact, 'user_KvIWsYcawt30pHo6Ra2qK')
   .then((response) => {
          console.log('SUCCESS!', response.status, response.text);
          // setContact(frmContact);
   }, (err) => {
          console.log('FAILED...', err);
   });
  }


    const handleOnSubmit = event => {
       fileValidator();
     event.preventDefault();
  }

  const onFileChange = event => { 
    // Update the state 
    setSelectedFile(event.target.files[0]);
  }; 
  const hasError = field =>
        formState.touched[field] && formState.errors[field] ? true : false;
        //END FORMS


  if (userLoadingError || loadingUsers) 
    return <p>loading</p>

    return (
      <div className={classes.root}>
          <Grid container justify="center" style={{marginBottom:10}}>
            <Grid item xs={6}>
               
              <form onSubmit={handleOnSubmit} className={classes.form} >
              {/* (data.users.length > 0 && data.users[0].roles == 'admin')?pages:pagesForUsers */}
              { (userData.users[0].roles === "admin" ? 
              <Button 
              id="botonOculto"
              color={showForm?'secondary':'primary'}
              fullWidth
              type="reset"
              size="large"
              variant="contained"
              onClick={(event)=>{
                setShowForm(!showForm);
                setFormState(formState => ({
                  ...formState,
                  values: {}
                }));
              }} 
              >{showForm?'CANCEL':'CREATE'}</Button>
            :
            <div></div>) }
              <Collapse in={showForm}>
                <TextField
                
                className={classes.separation,classes.backwhite}
                error={hasError('title')}
                fullWidth
                helperText={
                  hasError('title') ? formState.errors.title[0] : null
                }
                label="Title"
                name="title"
                className={classes.backwhite}
                onChange={handleChange}
                value={formState.values.title || ''}
                variant="outlined"
                />
                <TextField
                className={classes.separation,classes.backwhite}
                error={hasError('description')}
                fullWidth
                helperText={
                  hasError('description') ? formState.errors.description[0] : null
                }
                multiline
                label="Description"
                name="description"
                onChange={handleChange}
                value={formState.values.description || ''}
                variant="outlined"
                rows={4}
                />
                
                <TextField
                className={classes.separation}
                label="File"
                onChange={onFileChange}
                type="file"
                />
                <TextField
                type="hidden"
                name="filename"
                onChange={handleChange}
                value={formState.values.filename || 'No file Uploaded'}
                />
                <Button
                  className={classes.separation}
                  color="primary"
                  //disabled={((!formState.isValid) || loading)}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  SAVE
                </Button>
                </Collapse>
              </form>
              
            </Grid>
          </Grid>
        <AppBar position="static">
          <Tabs value={value} onChange={handleChangeIndexTab} aria-label="simple tabs example">
            <Tab label="MAINTENANCE STATEMENT" {...a11yProps(0)} />
            {/* <Tab label="AGM MINUTES" {...a11yProps(1)} /> */}
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
        {(loading === true)?<Grid><Typography>LOADING......</Typography></Grid>:
        <Grid
        container
        spacing={4}
        >
          {data.admin_items.filter((current)=>{return (current.type === 'maintenance_fee');}).map((current)=>{
            return (
              <Grid item xs={12}>
                <AdminItem 
                title={current.title} 
                description={current.description} 
                url={current.filename} 
                userType={userData.users[0].roles}
                id={`${current.id}`}
                created={`${current.created_at}`} />
              </Grid>
            );
          })}
        </Grid>
        }
        </TabPanel>
        {/* <TabPanel value={value} index={1}>
        {(loading === true)?<Grid><Typography>LOADING......</Typography></Grid>:
        <Grid
        container
        spacing={4}
        >
          {data.admin_items.filter((current)=>{return (current.type === 'minutes');}).map((current)=>{
            return (
              <Grid item xs={12}>
                <AdminItem 
                title={current.title} 
                description={current.description} 
                url={current.filename} 
                userType={userData.users[0].roles}
                id={`${current.id}`}
                created={`${current.created_at}`} />
              </Grid>
            );
          })}
        </Grid>
        }
        </TabPanel> */}
      </div>
    );
};

export default News;