import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  IconButton
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { DatePicker } from '@material-ui/pickers/DatePicker';
import PrintIcon from '@material-ui/icons/Print';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import moment from 'moment';

import { useHasuraUser } from 'hooks';
import { formattedNumber } from 'helpers';
import { makeStyles } from '@material-ui/styles';


import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// import MomentUtils from '@date-io/moment';

const TRANSACTIONS_PER_PAGE = 20;

const GET_USER_TRANSACTIONS = gql`
  query GetUserTransactions($id: Int!, $offset: Int!, $limit: Int!, $startDate: timestamptz, $endDate: timestamptz) {
    transactions_aggregate(where: { statement_account_id: { _eq: $id }, created_at: { _gte: $startDate, _lte: $endDate } }) {
      aggregate {
        count
      }
    }
    statement_account: statement_account_by_pk(id: $id) {
      id
      total
    }
    transactions(
      where: { statement_account_id: { _eq: $id }, created_at: { _gte: $startDate, _lte: $endDate } }
      offset: $offset
      limit: $limit
    ) {
      amount
      created_at
      exchange
      en_description
      currency
      transaction_type
      is_tip
    }
  }
`;

const GET_RECEPTION_DETAILS = gql`
  query GetReceptionAccount($id: Int!) {
    palapa_accounts(where: { statement_id: { _eq: $id } }) {
      rental_id
      user_id
      statement_id
      present
      commensal_name
      commensal_type
      apartment_name
      balance
      balance_retained
      tickets {
        id
        table {
          name
        }
        open
        tip
        total
      }
    }
  }
`;

const GET_STATEMENT_PDF = gql`
mutation MyMutation ($statement_account_id: Int!, $format: String!, $startDate: timestamptz, $endDate: timestamptz) {
  GenerateStatementPdf(finalDate: $endDate, folio: $statement_account_id, format: $format, initDate: $startDate){
    pdfContent
  }
}
`;

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  textContainer: {
    position: 'relative',
    backgroundColor: '#FFFFFF',
    padding: '8px',
    borderRadius: '4px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)',
    zIndex: 1,
    width: '100%',
    marginBottom: theme.spacing(2),
    textAlign: 'center', // Center the text
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  datePicker: {
    width: '150px',
  },
  searchBar: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const AccountStatement = () => {
  const today = new Date();
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(today.getMonth() - 1);
  const [startDate, setStartDate] = useState(oneMonthAgo.toISOString());
  const [endDate, setEndDate] = useState(today.toISOString());
  
  const base64ToBlob = (base64, type) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type });
  };


  const { user } = useHasuraUser();
  const [commensal, setCommensal] = useState(null);
  const [paginationInfo, setPaginationInfo] = useState({
    currentPage: 1,
    maxPages: 1
  });
  const [generateStatementPdf] = useMutation(GET_STATEMENT_PDF);

  const { data, refetch } = useQuery(GET_USER_TRANSACTIONS, {
    variables: {
      id: user?.id || 0,
      offset: (paginationInfo.currentPage - 1) * TRANSACTIONS_PER_PAGE,
      limit: TRANSACTIONS_PER_PAGE,
      startDate: startDate ? startDate : null,
      endDate: endDate ? endDate : null
    }
  });
  const { data: userData, loading } = useQuery(GET_RECEPTION_DETAILS, {
    variables: { id: user?.id },
    pollInterval: 1000
  });

  const handlePrintClick = async () => {
    try {
      const response = await generateStatementPdf({
        variables: {
          statement_account_id: user?.id,
          format: 'statementpdf',
          startDate: startDate,
          endDate: endDate
        }
      });    
        const pdfContent = response?.data?.GenerateStatementPdf?.pdfContent;
        const pdfBlob = base64ToBlob(pdfContent, 'application/pdf');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, '_blank');
        console.log('PDF generado correctamente' , 'success');
      } catch (error) {
          console.log('Error al generar el PDF', 'error');
      }   
    };

  useEffect(() => {
    if (userData?.palapa_accounts) {
      const [currentCommensal] = userData.palapa_accounts;
      setCommensal(currentCommensal);
    }
  }, [userData]);

  useEffect(() => {
    if (data?.transactions_aggregate) {
      const maxPages = Math.ceil(
        (data.transactions_aggregate.aggregate?.count || 0) /
          TRANSACTIONS_PER_PAGE
      );
      setPaginationInfo(prevState => ({ ...prevState, maxPages }));
    }
  }, [data]);

  useEffect(() => {
    refetch({
      id: user?.id || 0,
      offset: (paginationInfo.currentPage - 1) * TRANSACTIONS_PER_PAGE,
      limit: TRANSACTIONS_PER_PAGE,
      startDate: startDate ? startDate : null,
      endDate: endDate ? endDate : null
    });
  }, [startDate, endDate]);

  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} className={classes.textContainer}>
        <Typography variant="h6">
          Statement Account
        </Typography>
      </Grid>
      
      {/* Filters */}
      <Grid container row style={{ backgroundColor: 'white', padding: '16px', borderRadius: '8px' }}>
        <Grid item xs={5} >
          <TextField
            fullWidth
            helperText="Initial Date"
            label="Arrival Date"
            margin="dense"
            name="arrives_at"
            onChange={(event) => setStartDate(event.target.value)}
            value={startDate.slice(0, 10)}
            type="date"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={5}>
          <TextField
            fullWidth
            helperText="Final Date"
            label="Arrival Date"
            margin="dense"
            name="arrives_at"
            onChange={(event) => setEndDate(event.target.value)}
            value={endDate.slice(0, 10)}
            type="date"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={1} container justifyContent="flex-start">
          <IconButton color="primary" onClick={handlePrintClick}>
            <PrintIcon  />
          </IconButton>
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.textContainer}>
        <Typography variant="h6">
          {`${commensal?.apartment_name} ${commensal?.commensal_name}`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date and time</TableCell>
              <TableCell>Transaction type</TableCell>
              <TableCell align='right'>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.transactions ? (
              data.transactions.map((t, index) => (
                <TableRow key={index} style={{ backgroundColor: 'white' }}>
                  <TableCell>
                    {moment(t.created_at).format('MMMM Do YYYY, h:mm:ss a')}
                  </TableCell>
                  <TableCell>{t.transaction_type}</TableCell>
                  <TableCell align="right">
                    $ {formattedNumber(t.amount.toFixed(2))}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow style={{ backgroundColor: 'white' }}>
                <TableCell align="center" colSpan={3}>
                  No transactions found
                </TableCell>
              </TableRow>
            )}
            <TableRow style={{ backgroundColor: 'white' }}>
              <TableCell align="right" colSpan={2}>
                Total:
              </TableCell>
              <TableCell align="right">
                ${' '}
                {formattedNumber(
                  data?.statement_account?.total?.toFixed(2) || 0
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Grid
        container
        item
        justifyContent="center"
        spacing={2}
        style={{ marginTop: 20 }}
        xs={12}>
        <Grid item>
          <Pagination
            color="primary"
            count={paginationInfo.maxPages}
            onChange={(_, page) =>
              setPaginationInfo(prevState => ({
                ...prevState,
                currentPage: page
              }))
            }
            page={paginationInfo.currentPage}
            shape="rounded"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AccountStatement;
