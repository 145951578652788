import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import validate from 'validate.js';
import {
  Grid,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Button,
  Collapse,
  TextField
} from '@material-ui/core';
import userDataStore from '../../UserDataStore';
import { useSubscription, useMutation, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import NewsItem from './components/NewsItem';
import { values } from 'underscore';
import {
  useHistory,
  BrowserRouter as Router,
  Route,
  Link
} from 'react-router-dom';
import { render } from 'react-dom';
import firebase, { auth } from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { sendEmailHelper } from 'helpers';

const schema = {
  title: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  description: {
    presence: { allowEmpty: true }
  },
  filename: {
    presence: {
      allowEmpty: true
    }
  }
};

const AGMMINUTES = gql`
  subscription MySubscription {
    minutes(where: { minute_type_id: { _eq: 1 } }, order_by: { id: desc }) {
      id
      title
      filename
      minute_type_id
      created_at
      updated_at
      created_by
      deleted
      description
      sended
    }
  }
`;

const BOARDMINUTES = gql`
  subscription MySubscription {
    minutes(where: { minute_type_id: { _eq: 2 } }, order_by: { id: desc }) {
      id
      title
      filename
      minute_type_id
      created_at
      updated_at
      created_by
      deleted
      description
      sended
    }
  }
`;

const IMPROVEMENTMINUTES = gql`
  subscription MySubscription {
    minutes(where: { minute_type_id: { _eq: 3 } }, order_by: { id: desc }) {
      id
      title
      filename
      minute_type_id
      created_at
      updated_at
      created_by
      deleted
      description
      sended
    }
  }
`;

const CREATEMINUTE = gql`
  mutation MyMutation($object: minutes_insert_input!) {
    insert_minutes_one(object: $object) {
      id
      title
      filename
      description
      minute_type_id
      created_by
      created_at
      updated_at
    }
  }
`;

const GETUSERROLEBYEMAIL = gql`
  subscription getRole($email: String!) {
    users: users(where: { email: { _eq: $email } }) {
      first_name
      last_name
      email
      roles
    }
  }
`;

const USERSDATA = gql`
  query MyQuery {
    users(where: { send_notifications: { _eq: true } }) {
      first_name
      last_name
      email
      id
    }
  }
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    flexGrow: 1,
    backgroundImage: 'url(/images/alberca.jpg)'
    // backgroundColor: theme.palette.background.paper,
  },
  backwhite: {
    background: 'white'
  },
  fontcolor: {
    fontcolor: 'white'
  }
}));

const News = props => {
  const { userCondi, ...rest } = props;
  userDataStore.setTitle('');
  userDataStore.setTitle('Minutes');
  const [indexTab, setIndexTab] = useState(0);
  const classes = useStyles();
  const mostrar = true;
  const emailCustom =
    firebase.auth().currentUser && firebase.auth().currentUser.email;
  const {
    loading: agmLoading,
    error: agmError,
    data: agmData
  } = useSubscription(AGMMINUTES);
  const {
    loading: loadingUsers,
    error: errorUsers,
    data: dataUsers
  } = useQuery(USERSDATA);
  const {
    loading: boardLoading,
    error: boardError,
    data: boardData
  } = useSubscription(BOARDMINUTES);
  const {
    loading: improvementLoading,
    error: improvementError,
    data: improvementData
  } = useSubscription(IMPROVEMENTMINUTES);
  const {
    data: userData,
    loading: userLoadingError,
    error: userDataError
  } = useSubscription(GETUSERROLEBYEMAIL, {
    variables: { email: emailCustom }
  });

  //settings forms
  const [addItem, { data: itemData }] = useMutation(CREATEMINUTE);
  const [showForm, setShowForm] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const onFileChange = event => {
    // Update the state
    setSelectedFile(event.target.files[0]);
  };

  const fileValidator = () => {
    if (selectedFile === null) {
      alert('No file selected');
      saveTest();
    } else {
      onFileUpload();
    }
  };

  const onFileUpload = () => {
    console.log('llega al onFileUpload' + indexTab);
    // saveTest();
    // // alert("prueba mensaje" + selectedFile.name);
    // alert("archivo seleccionado " + selectedFile.name );

    // Create an object of formData
    const formData = new FormData();
    // Update the formData object
    formData.append('file', selectedFile, selectedFile.name);
    return fetch('https://services.costabonita.mx/upload', {
      method: 'POST',
      body: formData
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(json) {
        console.log('sin envio de correos');
        let values = formState.values;
        values.filename = `${json.data.folder}/${json.data.name}`;
        values.minute_type_id =
          indexTab === 0
            ? 1
            : indexTab === 1
            ? 2
            : indexTab === 2
            ? 3
            : indexTab === 3
            ? 5
            : 2;
        console.log(values);
        sendEmailNotification(`${json.data.folder}/${json.data.name}`);
        addItem({
          variables: {
            object: values
          }
        });
      });
  };

  const saveTest = () => {
    console.log('sin envio de correos');
    let values = formState.values;
    console.log('desde funcion save test ' + indexTab);
    addItem({
      variables: {
        object: {
          title: values.title,
          description: values.description,
          filename: 'images/1gfq8fecrsmuv9crjweafd.png',
          // filename: `${json.data.folder}/${json.data.name}`,
          minute_type_id: (values.minute_type_id =
            indexTab === 0
              ? 1
              : indexTab === 1
              ? 2
              : indexTab === 2
              ? 3
              : indexTab === 3
              ? 5
              : 2)
        }
      }
    });
  };

  const handleOnSubmit = event => {
    // onFileUpload()
    fileValidator();

    event.preventDefault();
  };
  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;
  //END FORMS

  const handleChangeIndexTab = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    setIndexTab(newValue);
  };

  const sendEmailNotification = Link => {
    console.log('sendEmailfunction');
    const usersEmail = dataUsers.users.map(usersEmailsList => {
      return usersEmailsList.email;
    });
    console.log(usersEmail);
    sendEmailHelper(['inupumper@gmail.com','carloscostabonita@yahoo.com.mx',...usersEmail], 'notNews', Link, 'Minute');
  };

  // const validate = () => {
  //   return true
  // }
  if (userLoadingError || loadingUsers || userLoadingError)
    return <p>loading</p>;

  return (
    <div className={classes.root}>
      <Grid container justify="center" style={{ marginBottom: 10 }}>
        <Grid item xs={6}>
          <form onSubmit={handleOnSubmit} className={classes.form}>
            {/* (data.users.length > 0 && data.users[0].roles == 'admin')?pages:pagesForUsers */}
            {userData?.users[0]?.roles === 'admin' ? (
              <Button
                id="botonOculto"
                color={showForm ? 'secondary' : 'primary'}
                fullWidth
                type="reset"
                size="large"
                variant="contained"
                onClick={event => {
                  setShowForm(!showForm);
                  setFormState(formState => ({
                    ...formState,
                    values: {}
                  }));
                }}>
                {showForm ? 'CANCEL' : 'CREATE'}
              </Button>
            ) : (
              <div></div>
            )}
            <Collapse in={showForm}>
              <TextField
                className={classes.separation}
                error={hasError('title')}
                fullWidth
                helperText={
                  hasError('title') ? formState.errors.title[0] : null
                }
                label="Title"
                name="title"
                className={classes.backwhite}
                onChange={handleChange}
                value={formState.values.title || ''}
                variant="outlined"
              />
              <TextField
                className={(classes.separation, classes.backwhite)}
                error={hasError('description')}
                fullWidth
                helperText={
                  hasError('description')
                    ? formState.errors.description[0]
                    : null
                }
                multiline
                label="Description"
                name="description"
                onChange={handleChange}
                value={formState.values.description || ''}
                variant="outlined"
                rows={4}
              />

              <TextField
                className={classes.separation}
                label="File"
                onChange={onFileChange}
                type="file"
              />
              <TextField
                type="hidden"
                name="filename"
                onChange={handleChange}
                value={formState.values.filename || 'No file Uploaded'}
              />
              <Button
                className={classes.separation}
                color="primary"
                //disabled={((!formState.isValid) || loading)}
                fullWidth
                size="large"
                type="submit"
                variant="contained">
                SAVE
              </Button>
            </Collapse>
          </form>
        </Grid>
      </Grid>
      <AppBar position="static">
        <Tabs
          value={indexTab}
          onChange={handleChangeIndexTab}
          aria-label="simple tabs example">
          <Tab label="AGM" {...a11yProps(0)} />
          <Tab label="BOARD" {...a11yProps(1)} />
          <Tab label="Improvement Committee" {...a11yProps(2)} />
          {/* <Tab label="TOWN HALL MEETINGS" {...a11yProps(3)} /> */}
        </Tabs>
      </AppBar>
      <TabPanel value={indexTab} index={0}>
        {agmLoading === true ? (
          <Grid>
            <Typography>LOADING......</Typography>
          </Grid>
        ) : (
          <Grid container spacing={4}>
            {agmData.minutes.map(current => {
              return (
                <Grid item xs={12}>
                  <NewsItem
                    title={current.title}
                    description={current.description}
                    url={current.filename}
                    userType={userData.users[0].roles}
                    id={`${current.id}`}
                    created={`${current.created_at}`}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
      </TabPanel>
      <TabPanel value={indexTab} index={1}>
        {boardLoading === true ? (
          <Grid>
            <Typography>LOADING......</Typography>
          </Grid>
        ) : (
          <Grid container spacing={4}>
            {boardData.minutes.map(current => {
              return (
                <Grid item xs={12}>
                  <NewsItem
                    title={current.title}
                    description={current.description}
                    url={current.filename}
                    id={`${current.id}`}
                    userType={userData.users[0].roles}
                    created={`${current.created_at}`}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
      </TabPanel>
      <TabPanel value={indexTab} index={2}>
        {improvementLoading === true ? (
          <Grid>
            <Typography>LOADING......</Typography>
          </Grid>
        ) : (
          <Grid container spacing={4}>
            {improvementData.minutes.map(current => {
              return (
                <Grid item xs={12}>
                  <NewsItem
                    title={current.title}
                    description={current.description}
                    url={current.filename}
                    id={`${current.id}`}
                    userType={userData.users[0].roles}
                    created={`${current.created_at}`}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
      </TabPanel>

      {/* <TabPanel value={indexTab} index={3}>
        {(townHallLoading === true)?<Grid><Typography>LOADING......</Typography></Grid>:
        <Grid
        container
        spacing={4}
        >
          {TownHallData.blog.map((current)=>{
            return (
              <Grid item xs={12}>
                <NewsItem 
                title={current.title} 
                description={current.description} 
                url={current.filename} 
                id={`${current.id}`}
                userType={userData.users[0].roles}
                created={`${current.created_at}`}
                 />
              </Grid>
            );
          })}
        </Grid>
        }
        </TabPanel> */}
    </div>
  );
};

export default News;
