import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from "react-router-dom";

import { AuthFirebaseProvider } from "./views/Auth/react-auth-firebase";
import history from "./utils/history";
import * as serviceWorker from './serviceWorker';

const mainRoutes = (
  <Router history={history}>
    <Route
      path="/"
      render={props => (
        <AuthFirebaseProvider
          // domain={AUTH_CONFIG.domain}
          // client_id={AUTH_CONFIG.clientId}
          // redirect_uri={AUTH_CONFIG.callbackUrl}
        />
      )}
    />
  </Router>
);

ReactDOM.render(mainRoutes, document.getElementById("root"));

serviceWorker.unregister();
