import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Divider } from '@material-ui/core';
import { AccountStatement } from './components';
import UserDataStore from '../../UserDataStore';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { useHasuraUser } from 'hooks';

const GET_RECEPTION_DETAILS = gql`
  query GetReceptionAccount($id: Int!) {
    palapa_accounts(where: { statement_id: { _eq: $id } }) {
      rental_id
      user_id
      statement_id
      present
      commensal_name
      commensal_type
      apartment_name
      balance
      balance_retained
      tickets {
        id
        table {
          name
        }
        open
        tip
        total
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  textContainer: {
    position: 'relative',
    backgroundColor: '#FFFFFF',
    padding: '8px',
    borderRadius: '4px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)',
    zIndex: 1,
    width: '100%', // Make the container take full width
  },
  textItem: {
    flexGrow: 1, // Allow item to grow and take available space
  },
}));

const Concierge = () => {
  const { user } = useHasuraUser();
  UserDataStore.setTitle('Statement Account');
  const classes = useStyles();
  const [commensal, setCommensal] = useState(null);
  const { data, loading } = useQuery(GET_RECEPTION_DETAILS, {
    variables: { id: user?.id },
    pollInterval: 1000
  });

  useEffect(() => {
    if (data?.palapa_accounts) {
      const [currentCommensal] = data.palapa_accounts;
      setCommensal(currentCommensal);
    }
  }, [data]);

  if (loading)
    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          loading.....
        </Grid>
      </div>
    );

  return (
    <div className={classes.root}>
      <Grid container spacing={6} alignItems="center">
        <Grid item xs={12} container justifyContent="space-between">
          <Grid item className={classes.textItem}>
            <div className={classes.textContainer}>
              <Typography variant="h6">
                {`${commensal?.apartment_name} ${commensal?.commensal_name}`}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      <AccountStatement />
    </div>
  );
};

export default Concierge;
