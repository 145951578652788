import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Collapse, TextField, Button } from '@material-ui/core';
import validate from 'validate.js';
import userDataStore from '../../UserDataStore';
import { useSubscription, useMutation,useQuery } from '@apollo/react-hooks';
import RulesAndRegulationsItem from './components/RulesAndRegulationsItem';
import { gql } from 'apollo-boost';
import firebase, { auth } from "firebase/app";
import "firebase/auth";
import "firebase/database";
import emailjs from 'emailjs-com';

const schema = {
  title: {
    presence: { allowEmpty: false, message: "is required" }
  },
  description: { 
    presence: { allowEmpty: true }
   },
  filename: { 
    presence: {
      allowEmpty: true
    }
   }
};

const CREATEGQL  = gql`
mutation CREATEADMINRULES($object: rules_and_regulations_insert_input!) {
  insert_rules_and_regulations_one(object: $object) {
    created_at
    description
    filename
    id
    title
    updated_at
  }
} 
`;

const GETUSERROLEBYEMAIL = gql `
subscription getRole($email: String!) {
  users:users(where: {email: {_eq: $email}}) {
    first_name
    last_name
    email
    roles
  }
}`;

const GETANDMINRULES = gql`
subscription MyQuery {
  rules_and_regulations(order_by: {created_at: desc}) {
    created_at
    description
    filename
    id
    title
    updated_at
  }
}
`;
const USERSDATA = gql`
query MyQuery {
  users(where: {send_notifications: {_eq: true}}) {
    first_name
    last_name
    email
    id
  }
}
`;


const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4)
    },
    form: {
      padding: 10,
      // backgroundColor: '#FFFFFF'
    },
    separation: {
      marginTop: 10
    },
    backGround: {
      backgroundColor: 'white'
    }
  }));

const RulesAndRegulations = ()=> {
    userDataStore.setTitle('rules and regulations');
    const {data:dataAdmin,loading:loadingAdmin,error:errorAdmin} = useSubscription(GETANDMINRULES);
    const emailCustom =  firebase.auth().currentUser && firebase.auth().currentUser.email;
    const {data:userData,loading:userLoadingError,error:userDataError} = useSubscription(GETUSERROLEBYEMAIL,{variables:{email:emailCustom}});
    const {loading:loadingUsers, error:errorUsers, data:dataUsers } = useQuery(USERSDATA);

    //settings forms
    const [addItem, {data}] = useMutation(CREATEGQL);
    const [showForm, setShowForm] = useState(false);
    const [selectedFile,setSelectedFile] = useState(null);
    const [formState, setFormState] = useState({
      isValid: false,
      values: {},
      touched: {},
      errors: {}
    });

    useEffect(() => {
      const errors = validate(formState.values, schema);
  
      setFormState(formState => ({
        ...formState,
        isValid: errors ? false : true,
        errors: errors || {}
      }));
    }, [formState.values]);

    const handleChange = event => {
      event.persist();
  
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [event.target.name]:
            event.target.type === 'checkbox'
              ? event.target.checked
              : event.target.value
        },
        touched: {
          ...formState.touched,
          [event.target.name]: true
        }
      }));
    };

    const onFileChange = event => { 
     
      // Update the state 
      setSelectedFile(event.target.files[0]);
    }; 

    const fileValidator = () =>{
      if (selectedFile === null){
        alert("No file selected");
        let values = formState.values;
        values.filename = "no  file selected";
        addItem({variables:{
          "object":values
        }})
        // sendEmailNotification();
      setShowForm(!showForm);
      }
      else{
        onFileUpload()
      }
    }
    const onFileUpload = () => { 
     
      // Create an object of formData 
      const formData = new FormData(); 
     
      // Update the formData object 
      formData.append( 
        "file", 
        selectedFile, 
        selectedFile.name 
      ); 
      return fetch('https://services.costabonita.mx/upload',{
        method: 'POST',
        body: formData
      })
      .then(function(response){
        return response.json();
      })
      .then(function(json){
        let values = formState.values;
        values.filename = `${json.data.folder}/${json.data.name}`;
        sendEmailNotification(`${json.data.folder}/${json.data.name}`);
        addItem({variables:{
          "object":values
        }})
      })
      setShowForm(!showForm);
    }; 

   

    const handleOnSubmit = event => {
      fileValidator()
      event.preventDefault();
    }

    const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;
    //END FORMS

    const classes = useStyles();

    const sendEmailNotification = (Link) => {
      console.log("sendEmailfunction");
      const usersEmail = dataUsers.users.map( usersEmailsList =>{
        return usersEmailsList.email } );
        console.log(usersEmail);
        //let correos = ["polito.no@hotmail.com","polo.nu16@gmail.com" , "carloscostabonita@yahoo.com.mx","mireya_costabonita@yahoo.com"]
  const frmContact = { userEmail: usersEmail, notification_type:'Rules and Regulations',file_link:'https://ownersapp.costabonita.mx/assets/'+ Link };
  // const [contact,setContact] = useState(frmContact);
        emailjs.send('CostaBonita','notNews', frmContact, 'user_KvIWsYcawt30pHo6Ra2qK')
   .then((response) => {
          console.log('SUCCESS!', response.status, response.text);
          // setContact(frmContact);
   }, (err) => {
          console.log('FAILED...', err);
   });
  }


  if (userLoadingError || loadingUsers ) 
    return <p>loading</p>


    return (
    <div className={classes.root}>
      {(loadingAdmin)?
      <Grid><Typography>LOADING......</Typography></Grid>
      :
      <Grid
      container
      spacing={4}
      >
        <Grid container justify="center">
          <Grid item xs={6}>
            
            <form onSubmit={handleOnSubmit} className={classes.form} >
                      
            { (userData.users[0].roles === "admin" ? 
            <Button 
            color={showForm?'secondary':'primary'}
            fullWidth
            type="reset"
            size="large"
            variant="contained"
            onClick={(event)=>{
              setShowForm(!showForm);
              setFormState(formState => ({
                ...formState,
                values: {}
              }));
            }} 
            >{showForm?'CANCEL':'CREATE'}</Button>
            :
            <div fontcolor="white"></div>) }
            <Collapse in={showForm}>
              <TextField
              className={classes.separation,classes.backGround}
              error={hasError('title')}
              fullWidth
              helperText={
                hasError('title') ? formState.errors.title[0] : null
              }
              label="Title"
              name="title"
              onChange={handleChange}
              value={formState.values.title || ''}
              variant="outlined"
              />
              <TextField
              className={classes.separation,classes.backGround}
              error={hasError('description')}
              fullWidth
              helperText={
                hasError('description') ? formState.errors.description[0] : null
              }
              multiline
              label="Description"
              name="description"
              onChange={handleChange}
              value={formState.values.description || ''}
              variant="outlined"
              rows={4}
              />
              
              <TextField
              className={classes.separation}
              label="File"
              onChange={onFileChange}
              type="file"
              />
              <TextField
              type="hidden"
              name="filename"
              onChange={handleChange}
              value={formState.values.filename || ''}
              />
              <Button
                className={classes.separation}
                color="primary"
                //disabled={((!formState.isValid) || loading)}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                SAVE
              </Button>
              </Collapse>
            </form>
            
          </Grid>
        </Grid>
        {dataAdmin.rules_and_regulations.map((current)=>{
          return (
            <Grid item xs={12}>
              <RulesAndRegulationsItem 
              title={current.title} 
              description={current.description} 
              url={`/assets/${current.filename}`} 
              created={`${current.created_at}`}
              userType={userData.users[0].roles} 
              id={`${current.id}`} />
            </Grid>
          )
        })}
      </Grid>
      }
    </div>
    );
};

export default RulesAndRegulations;