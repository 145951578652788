import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { UsersToolbar, UsersTable } from './components';

import UserDataStore from '../../UserDataStore';
import { useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase, { auth } from "firebase/app";
import "firebase/auth";
import "firebase/database";

const GETUSERVISITS = gql`
subscription getvisitusers {
visits(order_by: {created_at: desc}) {
  apartment_id
  arrives_at
  arrives_by
  canceled_at
  canceled_by
  car_color
  checking_in
  checking_in_by
  checking_out
  checking_out_by
  comments
  created_at
  created_by
  id
  lessor_id
  name
  output_comments
  plates_number
  status
  transport_type
  updated_at
  visitors_numbers
  apartment {
    building_id
    created_at
    id
    number
    name
    updated_at
    user_id
    building {
      created_at
      id
      name
      updated_at
    }
  }
}
}
`;

const GETUSERROLEBYEMAIL = gql `
subscription getRole($email: String!) {
  users:users(where: {email: {_eq: $email}}) {
    first_name
    last_name
    email
    roles
  }
}`;

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const Visits = (props) => {
  UserDataStore.setTitle('Visits');
  const classes = useStyles();
  const [query,setQuery] = useState('');
  const emailCustom =  firebase.auth().currentUser && firebase.auth().currentUser.email;
  const {data:userData,loading:userLoadingError,error:userDataError} = useSubscription(GETUSERROLEBYEMAIL,{variables:{email:emailCustom}});
  const {loading,error,data} = useSubscription(GETUSERVISITS);
  const HanddleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  if(loading || userLoadingError )
  return (
    <div className={classes.root}>
      <UsersToolbar query={query} setQuery={HanddleQueryChange.bind(this)} />
      <div className={classes.content}>
        <h1>loading.......</h1>
      </div>
    </div>
  );
  try {
    return (
      <div className={classes.root}>
        <UsersToolbar query={query} userData={`${userData.users}`}
        setQuery={HanddleQueryChange.bind(this)}/>
        <div className={classes.content}>
          <UsersTable visits={data.visits} userData={userData} search={query}/>
        </div>
      </div>
    );
  } catch (errorCatch) {
    return (
      <div className={classes.root}>
        <UsersToolbar query={query} setQuery={HanddleQueryChange.bind(this)}/>
        <div className={classes.content}>
          <h1>{error.message}</h1>
        </div>
      </div>
    );
  }
  
};

export default Visits;
